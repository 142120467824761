import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import {
  reservationDtoValidation,
  IReservationDto,
  EmptyReservationDto,
  ICheckRoomsAvailiableResponseDto,
  ISeasonCurePriceDto,
  IReservationResponseDto,
  IReservationRoomDto,
  IReservationCureDto,
} from '../model';
import {
  DateOnly,
  getGaUserIdFromEmail,
  getHashedPhoneNumber,
  gtmEvent,
  sum,
} from '../utility';
import { useTranslation } from 'react-i18next';
import { StyledStepper } from '../ui';
import { useEffect, useState } from 'react';
import {
  ContactPicker,
  CurePicker,
  PaymentPicker,
  PersonPicker,
  RangeDatePicker,
  RoomPicker,
} from './form-parts';
import {
  CheckApi,
  ICheckApi,
  INotificationService,
  IPromotionApi,
  PromotionApi,
} from '../services';
import { IReservationApi, ReservationApi } from '../services/reservationApi';
import { MapCureToAnalyticsItem, MapRoomToAnalyticsItem } from '../mapping';
import { IGaItemDto, IGaPurchaseEventDto } from '../model/analytics';
import { IPromotionDto } from '../model/IPromotionDto';
import { addDays, differenceInDays, format } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import { parseIntParam } from '../utility/param-helper';

export interface IFormProps {
  notificationService: INotificationService;
}

export function Form(props: IFormProps) {
  const { t, i18n } = useTranslation();

  const methods = useForm<IReservationDto>({
    resolver: yupResolver(reservationDtoValidation(t)),
    defaultValues: { ...EmptyReservationDto, contactLanguage: i18n.language },
    mode: 'onChange',
  });

  const [searchParams] = useSearchParams();
  const parsedCure = parseIntParam(searchParams.get('cure'));

  const fromWatch = methods.watch('from');
  const toWatch = methods.watch('to');
  const roomCountWatch = methods.watch('roomCount');
  const roomWatch: IReservationRoomDto[] = methods.watch('roomList');
  const cureWatch: IReservationCureDto[] = methods.watch('cureList');

  const checkApiService: ICheckApi = new CheckApi();
  const reservationApiService: IReservationApi = new ReservationApi();
  const promoApi: IPromotionApi = new PromotionApi();

  const [rooms, setRooms] = useState<ICheckRoomsAvailiableResponseDto[]>();
  const [cures, setCures] = useState<ISeasonCurePriceDto[]>();
  const [response, setResponse] = useState<IReservationResponseDto>();
  const [promotion, setPromotion] = useState<IPromotionDto>();

  useEffect(() => {
    if (!fromWatch || !toWatch) {
      setRooms(undefined);
      return;
    }

    checkApiService
      .checkRooms(fromWatch, toWatch, roomCountWatch, undefined)
      .then((e) => {
        setRooms(e);
      });
  }, [fromWatch, toWatch, roomCountWatch]);

  useEffect(() => {
    if (!fromWatch || !toWatch) {
      return;
    }

    checkApiService
      .checkCures(fromWatch, toWatch, parsedCure)
      .then((e) => setCures(e));
  }, [fromWatch, toWatch]);

  if (response) {
    return (
      <div className="mt-4 mb-10">
        <PaymentPicker response={response}></PaymentPicker>
      </div>
    );
  }

  const onSave = async (dto: IReservationDto): Promise<void> => {
    try {
      dto = {
        ...dto,
        promotion: promotion ?? null,
      };

      const result = await reservationApiService.addReservation(dto);

      //TRACK
      if (rooms && cures) {
        const items: IGaItemDto[] = [];

        roomWatch.forEach((room) => {
          const foundRoomAvailability = rooms.find(
            (e) => e.roomCategory.id === room.roomCategory.id
          );

          if (foundRoomAvailability) {
            items.push(
              MapRoomToAnalyticsItem(foundRoomAvailability, room.occupancy)
            );
          }
        });

        cureWatch.forEach((cure) => {
          const foundCurePrice = cures.find((e) => e.cure.id === cure.cure.id);

          if (foundCurePrice) {
            items.push(MapCureToAnalyticsItem(foundCurePrice));
          }
        });

        const payload: IGaPurchaseEventDto = {
          currency: 'EUR',
          value: sum(items, (f) => (f.price ?? 0) * (f.quantity ?? 1)),
          transaction_id: `reservation_${result.reservationId ?? 'unkown'}`,
          items: items,
        };

        gtmEvent('purchase', await getGaUserIdFromEmail(dto.contactMail), {
          ...payload,
          phone: getHashedPhoneNumber(dto.contactPhone),
        });
      }

      setResponse(result);
    } catch {
      props.notificationService.createNotification({
        title: 'ERROR',
        type: 'error',
        description: 'CALL REZEPTION',
        hideAfterSeconds: 10,
      });
    }
  };

  const loadPromotionByCode = (code: string) => {
    if (!code) {
      setPromotion(undefined);
      return;
    }

    promoApi
      .find(code)
      .then((e) => {
        if (e !== null) {
          const days = Math.abs(differenceInDays(toWatch, fromWatch));

          if (days < e.minStayDays) {
            props.notificationService.createNotification({
              type: 'failure',
              title: `${t('reservation.promo.days-not-fulfilled')} ${
                e.minStayDays
              }`,
              hideAfterSeconds: 10,
            });
          } else if (e.bookableFrom && e.bookableFrom >= fromWatch) {
            props.notificationService.createNotification({
              type: 'failure',
              title: `${t('reservation.promo.range-not-fulfilled')} ${format(
                e.bookableFrom,
                'dd.MM.yyyy'
              )} - ${format(
                e.bookableTo ?? addDays(e.bookableFrom, 365),
                'dd.MM.yyyy'
              )}`,
              hideAfterSeconds: 10,
            });
          } else if (e.bookableTo && e.bookableTo <= toWatch) {
            props.notificationService.createNotification({
              type: 'failure',
              title: `${t('reservation.promo.range-not-fulfilled')} ${format(
                e.bookableFrom ?? addDays(e.bookableTo, -365),
                'dd.MM.yyyy'
              )} - ${format(e.bookableTo, 'dd.MM.yyyy')}`,
              hideAfterSeconds: 10,
            });
          } else {
            setPromotion(e);
          }
        } else {
          props.notificationService.createNotification({
            type: 'failure',
            title: t('reservation.promo.failed'),
            hideAfterSeconds: 10,
          });
        }
      })
      .catch(() => {
        props.notificationService.createNotification({
          type: 'error',
          title: t('reservation.promo.failed'),
          hideAfterSeconds: 10,
        });
      });
  };

  return (
    <FormProvider {...methods}>
      <div className="mt-4 mb-32">
        <form
          onSubmit={methods.handleSubmit((data) => {
            return onSave(data).then(() => methods.reset(data));
          })}
        >
          <StyledStepper blockNextClick={true}>
            <PersonPicker></PersonPicker>

            <RangeDatePicker
              notificationService={props.notificationService}
            ></RangeDatePicker>

            <CurePicker cures={cures ?? []}></CurePicker>

            <RoomPicker
              notificationService={props.notificationService}
              roomCategoryAvailiableList={rooms ?? []}
            ></RoomPicker>

            <ContactPicker
              notificationService={props.notificationService}
              cures={cures ?? []}
              roomCategoryAvailiableList={rooms ?? []}
              promotion={promotion ?? null}
              loadPromotionByCode={(code: string) => loadPromotionByCode(code)}
            ></ContactPicker>
          </StyledStepper>
        </form>
      </div>
    </FormProvider>
  );
}

export default Form;
