import * as yup from 'yup';
import { TFunction } from 'i18next';
import { IVoucherDto } from '../IVoucherDto';

export function voucherDtoValidation(
  translation: TFunction<'translation', undefined>
): yup.ObjectSchema<IVoucherDto> {
  return yup
    .object({
      id: yup.number().required(),

      contactLanguage: yup.string().oneOf(['de', 'en']).required(),
      contactMail: yup
        .string()
        .email(translation('errors.required'))
        .required(translation('errors.required')),
      contactPhone: yup
        .string()
        .trim()
        .transform((e) => (e === '' ? null : e))
        .nullable()
        .defined(),
      contactTitle: yup
        .string()
        .trim()
        .transform((e) => (e === '' ? null : e))
        .nullable()
        .defined(),
      contactFirstname: yup
        .string()
        .trim()
        .transform((e) => (e === '' ? null : e))
        .required(translation('errors.required')),
      contactLastname: yup
        .string()
        .trim()
        .transform((e) => (e === '' ? null : e))
        .required(translation('errors.required')),
      contactStreet: yup
        .string()
        .trim()
        .transform((e) => (e === '' ? null : e))
        .required(translation('errors.required')),
      contactStreetNr: yup
        .string()
        .trim()
        .transform((e) => (e === '' ? null : e))
        .required(translation('errors.required')),
      contactPostal: yup
        .string()
        .trim()
        .transform((e) => (e === '' ? null : e))
        .required(translation('errors.required')),
      contactCity: yup
        .string()
        .trim()
        .transform((e) => (e === '' ? null : e))
        .required(translation('errors.required')),
      contactCountry: yup
        .string()
        .trim()
        .transform((e) => (e === '' ? null : e))
        .required(translation('errors.required')),

      code: yup.string().defined().nullable(),
      title: yup.string().required(translation('errors.required')),
      text: yup.string().required(translation('errors.required')),
      value: yup.number().required(translation('errors.required')),
    })
    .required();
}
