import classNames from 'classnames';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';
import React, { HTMLInputTypeAttribute, useRef } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { format } from 'date-fns';
import StyledButton from './styled-button';

export function PromotionInput(args: {
  onChange?: (data: string) => void;

  title?: string | React.ReactNode;
  hint?: string | React.ReactNode;
  disabled?: boolean;
}) {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div>
      {args?.title && (
        <label className="block font-medium text-gray-700">
          {args?.title}

          {args?.hint && (
            <span className="text-sm ml-1 text-gray-500">{args.hint}</span>
          )}
        </label>
      )}

      <div className="flex gap-2 relative  mt-1">
        <div className="relative block w-full shadow-sm">
          <input
            ref={inputRef}
            className="w-full rounded-md min-w-0 flex-1 border-gray-300 focus:border-primary-800 focus:ring-primary-800 sm:text-sm"
            disabled={args?.disabled}
          />
        </div>

        <StyledButton
          type="button"
          className="text-center"
          onClick={() =>
            args.onChange && args.onChange(inputRef.current?.value ?? '')
          }
        >
          Speichern
        </StyledButton>
      </div>
    </div>
  );
}

export default PromotionInput;
