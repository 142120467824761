import * as yup from 'yup';
import { TFunction } from 'i18next';
import { IPromotionDto, PromotionType } from '../IPromotionDto';
import { DateOnly } from '../../utility';

export function promotionDtoValidation(
  translation: TFunction<'translation', undefined>
): yup.ObjectSchema<IPromotionDto> {
  return yup
    .object({
      id: yup.number().required(),

      code: yup.string().required(translation('errors.required')),
      minStayDays: yup.number().required(translation('errors.required')),
      mustHaveCure: yup.bool().required(translation('errors.required')),

      redeemableFrom: yup
        .date()
        .transform((e) => (e ? new DateOnly(e) : null))
        .nonNullable(translation('errors.required'))
        .defined(translation('errors.required')),
      redeemableTo: yup
        .date()
        .transform((e) => (e ? new DateOnly(e) : null))
        .nonNullable(translation('errors.required'))
        .defined(translation('errors.required')),

      bookableFrom: yup
        .date()
        .transform((e) => (e ? new DateOnly(e) : null))
        .nullable()
        .defined(translation('errors.required')),
      bookableTo: yup
        .date()
        .transform((e) => (e ? new DateOnly(e) : null))
        .nullable()
        .defined(translation('errors.required')),

      type: yup.mixed<PromotionType>().required(translation('errors.required')),
      value: yup.number().required(translation('errors.required')),
    })
    .required();
}
